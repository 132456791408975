/* eslint-disable no-shadow, @typescript-eslint/no-explicit-any */
enum EmployeeStatus {
  STANDARD = 'standard',
  BASIC = 'basic',
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  PENDING_CLOSED = 'pending_closed',
}

enum EmploymentStatus {
  ACTIVE = 'active',
  PENDING_EMPLOYER = 'pending_employer',
  PENDING_CONFIRMATION = 'pending_confirmation',
  PENDING_RESIGNATION = 'pending_resignation',
  PENDING_UNBIND = 'pending_unbind',
  UNBIND = 'unbind',
  UNBINDED = 'unbinded',
  EMPLOYER_SUSPEND = 'employer_suspend',
  REJECTED = 'rejected',
  RESIGNED = 'resigned',
  SUSPENDED = 'suspended',
  UNSUSPEND = 'unsuspend',
}

enum EmployeeSalaryFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  HOURLY = 'hourly',
}

enum EmployeeBulkImportRequestStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

enum EmployeeTransactionType {
  WITHDRAW = 'withdraw',
  CREDIT = 'credit',
  EARNING = 'earning',
  FEE = 'fee',
  ADJUSTMENT = 'adjustment',
  REPAYMENT = 'repayment',
  REWARDS = 'rewards',
  REMIT = 'remit',
  PAYMENT = 'payment',
}

enum ConsentCategory {
  REGISTRATION = 'registration',
  DEDUCTION = 'deduction',
}

enum CertificateType {
  BOTH = 'both',
  LIST = 'list',
}

const EmploymentStatusColor: any = {
  [EmploymentStatus.PENDING_EMPLOYER]: 'warning',
  [EmploymentStatus.PENDING_RESIGNATION]: 'warning',
  [EmploymentStatus.ACTIVE]: 'success',
  [EmploymentStatus.RESIGNED]: 'danger',
  [EmploymentStatus.REJECTED]: 'danger',
  [EmploymentStatus.SUSPENDED]: 'danger',
  undefined,
};

const EmploymentStatusDescription: any = {
  [EmploymentStatus.PENDING_CONFIRMATION]: 'Employment profile has not been binded',
  [EmploymentStatus.PENDING_RESIGNATION]: 'Employment profile will be resigned on resignation date',
  [EmploymentStatus.ACTIVE]: 'Employment profile has been binded',
  [EmploymentStatus.RESIGNED]: 'Employment profile has been resigned',
  [EmploymentStatus.UNBINDED]: 'Employment profile has been unbinded from the user',
  [EmploymentStatus.EMPLOYER_SUSPEND]: 'Employment profile has been suspended by the employer',
  [EmploymentStatus.SUSPENDED]: 'Employment profile has been suspended',
};

const transactionTypeLabel: any = {
  [EmployeeTransactionType.CREDIT]: 'label.creditEarned',
  [EmployeeTransactionType.FEE]: 'label.fee',
  [EmployeeTransactionType.WITHDRAW]: 'label.withdraw',
  [EmployeeTransactionType.EARNING]: 'label.earning',
  [EmployeeTransactionType.REWARDS]: 'label.redemption',
  [EmployeeTransactionType.REPAYMENT]: 'label.repayment',
  [EmployeeTransactionType.REMIT]: 'label.remit',
  [EmployeeTransactionType.ADJUSTMENT]: 'label.adjustment',
  [EmployeeTransactionType.PAYMENT]: 'label.payment',
};

const employmentStatusSelect: any = [
  { label: 'status.active', value: EmploymentStatus.ACTIVE },
  { label: 'status.pending_employer', value: EmploymentStatus.PENDING_EMPLOYER },
  { label: 'status.pending_confirmation', value: EmploymentStatus.PENDING_CONFIRMATION },
  { label: 'status.rejected', value: EmploymentStatus.REJECTED },
  { label: 'status.resigned', value: EmploymentStatus.RESIGNED },
  { label: 'status.suspended', value: EmploymentStatus.SUSPENDED },
];

const employmentProfileStatusLabel: { [key: string]: string } = {
  [EmploymentStatus.ACTIVE]: 'status.active',
  [EmploymentStatus.PENDING_CONFIRMATION]: 'status.pending',
  [EmploymentStatus.EMPLOYER_SUSPEND]: 'status.suspended',
  [EmploymentStatus.PENDING_RESIGNATION]: 'status.pend.Resign',
  [EmploymentStatus.PENDING_UNBIND]: 'status.pend.Unbind',
  [EmploymentStatus.RESIGNED]: 'status.resigned',
  [EmploymentStatus.UNBINDED]: 'status.unbinded',
};

const suspendedStatusSelect: { label: string; value: string }[] = [
  { label: 'label.true', value: 'true' },
  { label: 'label.false', value: 'false' },
];

const employmentProfileStatusSelect: { label: string; value: string }[] = [
  { label: employmentProfileStatusLabel[EmploymentStatus.ACTIVE], value: EmploymentStatus.ACTIVE },
  {
    label: employmentProfileStatusLabel[EmploymentStatus.PENDING_CONFIRMATION],
    value: EmploymentStatus.PENDING_CONFIRMATION,
  },
  { label: employmentProfileStatusLabel[EmploymentStatus.EMPLOYER_SUSPEND], value: EmploymentStatus.EMPLOYER_SUSPEND },
  { label: 'status.pendingResignation', value: EmploymentStatus.PENDING_RESIGNATION },
  { label: employmentProfileStatusLabel[EmploymentStatus.RESIGNED], value: EmploymentStatus.RESIGNED },
  { label: 'status.pendingUnbind', value: EmploymentStatus.PENDING_UNBIND },
  { label: employmentProfileStatusLabel[EmploymentStatus.UNBINDED], value: EmploymentStatus.UNBINDED },
];

const employeeSalaryFrequencySelect: any = [
  { label: 'label.daily', value: EmployeeSalaryFrequency.DAILY },
  { label: 'label.weekly', value: EmployeeSalaryFrequency.WEEKLY },
  { label: 'label.monthly', value: EmployeeSalaryFrequency.MONTHLY },
  { label: 'label.hourly', value: EmployeeSalaryFrequency.HOURLY },
];

const employeeBulkImportRequestStatusSelect: any = [
  { label: 'status.approved', value: EmployeeBulkImportRequestStatus.APPROVED },
  { label: 'status.pending', value: EmployeeBulkImportRequestStatus.PENDING },
  { label: 'status.rejected', value: EmployeeBulkImportRequestStatus.REJECTED },
];

const transactionTypeSelect: any = [
  { label: transactionTypeLabel[EmployeeTransactionType.ADJUSTMENT], value: EmployeeTransactionType.ADJUSTMENT },
  { label: transactionTypeLabel[EmployeeTransactionType.CREDIT], value: EmployeeTransactionType.CREDIT },
  { label: transactionTypeLabel[EmployeeTransactionType.EARNING], value: EmployeeTransactionType.EARNING },
  { label: transactionTypeLabel[EmployeeTransactionType.FEE], value: EmployeeTransactionType.FEE },
  { label: transactionTypeLabel[EmployeeTransactionType.PAYMENT], value: EmployeeTransactionType.PAYMENT },
  { label: transactionTypeLabel[EmployeeTransactionType.REWARDS], value: EmployeeTransactionType.REWARDS },
  { label: transactionTypeLabel[EmployeeTransactionType.WITHDRAW], value: EmployeeTransactionType.WITHDRAW },
];

const employerTransactionTypeSelect: any = [
  { label: transactionTypeLabel[EmployeeTransactionType.FEE], value: EmployeeTransactionType.FEE },
  { label: transactionTypeLabel[EmployeeTransactionType.WITHDRAW], value: EmployeeTransactionType.WITHDRAW },
  { label: transactionTypeLabel[EmployeeTransactionType.PAYMENT], value: EmployeeTransactionType.PAYMENT },
];

const downloadSingleConsentCategory: any = [
  { label: 'label.registrationCertificate', value: ConsentCategory.REGISTRATION },
  { label: 'label.deductionCertificate', value: ConsentCategory.DEDUCTION },
];

const certificateTypeSelect: any = [
  { label: 'label.certificateTypeList', value: CertificateType.LIST },
  // { label: 'label.certificateTypeBoth', value: CertificateType.BOTH },
];

export {
  EmployeeStatus,
  EmploymentStatus,
  employmentStatusSelect,
  EmployeeSalaryFrequency,
  employeeSalaryFrequencySelect,
  EmployeeBulkImportRequestStatus,
  employeeBulkImportRequestStatusSelect,
  EmployeeTransactionType,
  transactionTypeSelect,
  employerTransactionTypeSelect,
  transactionTypeLabel,
  EmploymentStatusColor,
  EmploymentStatusDescription,
  employmentProfileStatusLabel,
  employmentProfileStatusSelect,
  suspendedStatusSelect,
  downloadSingleConsentCategory,
  certificateTypeSelect,
  CertificateType,
};
