/* eslint-disable @typescript-eslint/no-explicit-any */
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FormLabelAlign } from 'antd/lib/form/interface';
import { useField } from 'formik';
import React, {
  ChangeEventHandler,
  CSSProperties,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useMemo,
} from 'react';
import { FormItem, Input as FormikAdtdInput } from 'formik-antd';
import { useTranslation } from 'react-i18next';

export interface TextInputProp {
  placeholder: string;
  label?: string | ReactNode;
  id: string;
  name: string;
  className?: string;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: ChangeEventHandler<any>;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  // requiredMark?: any;
  allowClear?: boolean;
  required?: boolean;
  bordered?: boolean;
  noStyle?: boolean;
  disabled?: boolean;
  maxLength?: number;
  size?: SizeType;
  tooltip?: ReactNode;
  suffix?: ReactNode;
  addonAfter?: ReactNode;
  children?: ReactNode;
  type?: string;
  help?: ReactNode;
  labelAlign?: FormLabelAlign; // default: right
  labelCol?: object; // ant design Col object
  wrapperCol?: object; // ant design Col object
  rules?: any;
  style?: CSSProperties;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  onKeyPress?: KeyboardEventHandler;
  validateStatus?: '' | 'success' | 'warning' | 'error' | 'validating';
  readOnly?: boolean;
  isUpdatedField?: boolean;
}

export const TextInput: React.FC<TextInputProp> = (props) => {
  const {
    className,
    label,
    name,
    tooltip,
    labelCol,
    wrapperCol,
    labelAlign,
    required,
    rules,
    noStyle,
    style,
    id,
    value,
    defaultValue,
    bordered,
    placeholder,
    maxLength,
    size,
    allowClear,
    suffix,
    type,
    onChange,
    onPressEnter,
    disabled,
    addonAfter,
    onFocus,
    onBlur,
    onKeyPress,
    readOnly,
    children,
    help,
    validateStatus,
    isUpdatedField,
  } = props;
  const [field, meta] = useField({ name, value });
  const { t } = useTranslation();

  const displayValidateStatus = useMemo(() => {
    if (meta.touched && meta.error) return 'error';

    return validateStatus;
  }, [meta.error, meta.touched, validateStatus]);

  const onIsUpdatedField = useMemo(() => {
    if (isUpdatedField) {
      return 'is-updated-field';
    }
    return '';
  }, [isUpdatedField]);

  return (
    <FormItem
      className={className}
      label={label}
      name={field.name}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      required={required}
      rules={rules}
      noStyle={noStyle}
      help={help ?? (meta.touched && meta.error) ? t(`${meta.error}`) : undefined}
      validateStatus={displayValidateStatus}
    >
      <FormikAdtdInput
        ref={null}
        style={style}
        id={id}
        name={field.name}
        className={onIsUpdatedField}
        value={field.value || value}
        defaultValue={defaultValue}
        bordered={bordered}
        placeholder={placeholder}
        maxLength={maxLength}
        size={size}
        allowClear={allowClear}
        suffix={suffix}
        type={type}
        onChange={onChange}
        onPressEnter={onPressEnter}
        disabled={disabled}
        addonAfter={addonAfter}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        readOnly={readOnly}
      />
      {children}
    </FormItem>
  );
};

TextInput.defaultProps = {
  allowClear: true,
  labelAlign: 'left',
};
