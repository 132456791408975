import { Button, Dropdown, Image, Tooltip, Space } from 'antd';
import { ButtonType } from 'antd/lib/button/button';
import { CountryCode, countryCodeConvertImage, countryCodeList, countryTimezoneUtcOffset } from 'common';
import { changeCountryCode, changeLanguageCode, RootState, selectCurrentCountry } from 'modules';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

export interface CountryDropdownProp {
  type?: ButtonType;
  btnClassName?: string;
}

export const CountryDropdown: React.FC<CountryDropdownProp> = ({ type, btnClassName }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const countryCode = useSelector((state: RootState) => selectCurrentCountry(state));

  const onClickLocalizationMenu = useCallback(
    (e: { key: string }) => {
      if (countryCode === e.key) return;
      dispatch(changeCountryCode({ code: e.key }));
      dispatch(changeLanguageCode('en'));
      moment.tz.setDefault(e.key as CountryCode);
      // if ([CountryCode.HK, CountryCode.ID, CountryCode.PH].indexOf(e.key as CountryCode) !== -1)
      //   history.push('/employer/list');
      if (e.key === CountryCode.MY) history.push('/dashboard');
      if (e.key === CountryCode.PH) history.push('/dashboard');
      if (e.key === CountryCode.ID) history.push('/dashboard');
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1);
    },
    [dispatch, history, countryCode]
  );

  // const localizationMenu = (): MenuProps => {
  //   const items = countryCodeList.map((item) =>
  //     getItem(
  //       <Tooltip title={item.name}>
  //         <Image preview={false} className="country-flag" src={item.image} />
  //       </Tooltip>,
  //       item.code
  //     )
  //   );
  //   return (
  //     <Menu onClick={onClickLocalizationMenu} selectedKeys={[countryCode]} className="country-menu" items={items} />
  //   );
  // };

  const localizationMenu = () => {
    return countryCodeList.map((item: { code: CountryCode; name: string; image: string }) => ({
      label: (
        <Tooltip title={t(item.name)}>
          <Space className="align-items-baseline">
            <Image preview={false} className="country-flag" src={item.image} />
            <span>
              {item.code.toUpperCase()} ({countryTimezoneUtcOffset[item.code]})
            </span>
          </Space>
        </Tooltip>
      ),
      key: item.code,
    }));
  };

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlayClassName="country-dropdown"
      menu={{
        items: localizationMenu(),
        onClick: onClickLocalizationMenu,
        selectedKeys: [countryCode],
        className: 'country-menu',
      }}
    >
      <Button className={btnClassName}>
        <Space className="align-items-baseline">
          <Image preview={false} className="country-flag" src={countryCodeConvertImage[countryCode]} />
          <span>
            {countryCode.toUpperCase()} ({countryTimezoneUtcOffset[countryCode]})
          </span>
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
