import { useField } from 'formik';
import moment, { Moment } from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DisabledTime } from 'rc-picker/lib/interface';
import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { FormItem, DatePicker } from 'formik-antd';
import { useTranslation } from 'react-i18next';

export interface DatePickerInputProp {
  label?: string | ReactNode;
  className?: string;
  formClassName?: string;
  id: string;
  name: string;
  value?: moment.Moment;
  defaultValue?: moment.Moment;
  placeholder?: string;
  format?: string;
  tooltip?: ReactNode;
  wrapperCol?: object; // ant design Col object
  labelCol?: object; // ant design Col object
  labelAlign?: 'right' | 'left';
  required?: boolean;
  allowClear?: boolean;
  disabled?: boolean;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  inputReadOnly?: boolean;
  showTime?: object | boolean;
  onChange?: (value: moment.Moment | null, dateString: string) => void;
  disabledDate?: (currentDate: moment.Moment) => boolean;
  disabledTime?: DisabledTime<Moment>;
  style?: CSSProperties;
  validateStatus?: '' | 'success' | 'warning' | 'error' | 'validating';
}

export const DatePickerInput: React.FC<DatePickerInputProp> = ({
  className,
  formClassName,
  label,
  name,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  onChange,
  value,
  defaultValue,
  id,
  placeholder,
  disabledDate,
  disabledTime,
  style,
  showTime,
  picker,
  format,
  disabled,
  inputReadOnly,
  allowClear,
  validateStatus,
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField({ name });

  const displayValidateStatus = useMemo(() => {
    if (meta.touched && meta.error) return 'error';

    return validateStatus;
  }, [meta.error, meta.touched, validateStatus]);

  return (
    <FormItem
      className={formClassName}
      label={label}
      name={field.name}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
      validateStatus={displayValidateStatus}
      required={required}
      help={meta.touched && meta.error ? t(`${meta.error}`) : undefined}
    >
      <DatePicker
        className={className}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        id={id}
        name={field.name}
        placeholder={placeholder}
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        style={style}
        showTime={showTime}
        format={format}
        disabled={disabled}
        inputReadOnly={inputReadOnly}
        picker={picker}
        allowClear={allowClear}
      />
    </FormItem>
  );
};

DatePickerInput.defaultProps = {
  allowClear: true,
  labelAlign: 'left',
  style: { width: '100%' },
};
