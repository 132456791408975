import { CountryCode } from 'common';
import {
  UPDATE_USERS_CREDENTIALS,
  SET_USERS_CREDENTIALS,
  SET_ACCESS_TOKEN,
  SET_IS_LOGGED_IN,
  REFRESH_ACCESS_TOKEN,
  CHANGE_PASSWORD,
} from './constants';

export interface ISetUserCredential {
  type: typeof SET_USERS_CREDENTIALS;
  payload: {
    refreshToken: string;
    expiredOn: number;
    accessToken: string;
    userProfile: {
      email: string;
      name: string;
    };
    eid: string | undefined;
    employers: {
      eid: string;
      companyName: string;
      country: CountryCode;
      role: string;
    }[];
    permissions: string[];
  };
}

export interface IUpdateUserCredential {
  type: typeof UPDATE_USERS_CREDENTIALS;
  payload: {
    refreshToken: string;
    expiredOn: number;
    accessToken: string;
  };
}

export interface ISetIsLoggedIn {
  type: typeof SET_IS_LOGGED_IN;
  payload: boolean;
}

export interface ISetAccessToken {
  type: typeof SET_ACCESS_TOKEN;
  payload: string;
}

export interface IRefreshAccessToken {
  type: typeof REFRESH_ACCESS_TOKEN;
  payload: {
    refreshToken: string;
  };
}

export interface IChangePassword {
  type: typeof CHANGE_PASSWORD;
  payload: {
    oldPassword: string;
    password: string;
  };
}

export type UserAction =
  | ISetUserCredential
  | ISetAccessToken
  | IRefreshAccessToken
  | ISetIsLoggedIn
  | IChangePassword
  | IUpdateUserCredential;

export const setUserCredential = (payload: ISetUserCredential['payload']): ISetUserCredential => ({
  type: SET_USERS_CREDENTIALS,
  payload,
});

export const updateUserCredential = (payload: IUpdateUserCredential['payload']): IUpdateUserCredential => ({
  type: UPDATE_USERS_CREDENTIALS,
  payload,
});

export const setIsLoggedIn = (payload: ISetIsLoggedIn['payload']): ISetIsLoggedIn => ({
  type: SET_IS_LOGGED_IN,
  payload,
});

export const setAccessToken = (payload: ISetAccessToken['payload']): ISetAccessToken => ({
  type: SET_ACCESS_TOKEN,
  payload,
});

export const refreshAccessToken = (payload: IRefreshAccessToken['payload']): IRefreshAccessToken => ({
  type: REFRESH_ACCESS_TOKEN,
  payload,
});
